'format es6';
'use strict';

import noUiSlider from 'nouislider';

function updateValue(sliderContainer, minValueHandle, maxValueHandle) {
	const setMinValue = minValueHandle.value;
	const setMaxValue = maxValueHandle.value;

	sliderContainer.setAttribute('data-minimum-set', setMinValue);
	sliderContainer.setAttribute('data-maximum-set', setMaxValue);

	sliderContainer.querySelector('[data-minimum-price]').setAttribute('name', 'search[minimum]');
	sliderContainer.querySelector('[data-maximum-price]').setAttribute('name', 'search[maximum]');
}

export default {
	init() {
		const sliderContainer = document.querySelector('[data-slider-container]');
		if (!sliderContainer) return;
		const handlesSlider = sliderContainer.querySelector('[data-price-slider]');

		if (handlesSlider) {
			const minTotal = Number(sliderContainer.getAttribute('data-minimum'));
			const maxTotal = Number(sliderContainer.getAttribute('data-maximum'));
			const minValueHandle = sliderContainer.querySelector('[data-minimum-price]');
			const maxValueHandle = sliderContainer.querySelector('[data-maximum-price]');
	
			const bothValues = [
				minValueHandle,
				maxValueHandle,
			];
			
			noUiSlider.create(handlesSlider, {
				start: [minTotal, maxTotal],
				connect: true,
				range: {
					min: [minTotal],
					max: [maxTotal],
				},
			});
	
			handlesSlider.noUiSlider.on('update', (values, handle) => {
				const noDecimal = Math.floor(values[handle]);
				bothValues[handle].value = noDecimal;
			});	

			handlesSlider.noUiSlider.on('change', () => {
				updateValue(sliderContainer, minValueHandle, maxValueHandle);
			});	
			
			bothValues.forEach((input, handle) => {
				input.addEventListener('change', (curr) => {
					handlesSlider.noUiSlider.setHandle(handle, curr.currentTarget.value);

					updateValue(sliderContainer, minValueHandle, maxValueHandle);
				});
			});
		}		
	},

	destroy() {
		const sliderContainer = document.querySelector('[data-slider-container]');
		const handlesSlider = sliderContainer.querySelector('[data-price-slider]');

		handlesSlider.noUiSlider.destroy();
	},
};
