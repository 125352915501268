'format es6';
'use strict';

import reqwest from 'reqwest';
import { TimelineMax, TweenMax, Expo } from 'gsap/TweenMax';

import ns from './NameSpace';
import { slideDown, slideUp } from './utils/slide';
import { scrollToElem } from './utils/scrollTo';

const MAX_FILE_SIZE = 2; // In Mo
const MAX_IMG_SIZE = 7; // In Mo

function getFileInfos(input) {
	const type = input.getAttribute('data-input-type');
	const inputFile = input.files;

	if (!inputFile[0]) return false;
	const inputExt = input.value.split('.').pop();
	const size = inputFile ? parseFloat((input.files[0].size / 1024 / 1024).toFixed(2)) : 0;

	if (type === 'img') {
		if ((inputExt === 'jpg' || inputExt === 'jpeg' || inputExt === 'png') && size < MAX_IMG_SIZE) {
			return true;
		}
	} else if ((inputExt === 'pdf' || inputExt === 'docx' || inputExt === 'doc') && size < MAX_FILE_SIZE) {
		return true;
	}

	return false;
}

function validForm(e) {
	e.preventDefault();
	const formToValidate = e.currentTarget;
	const errorMessage = formToValidate.querySelector('.error-message');
	
	const inputs = Array.from(formToValidate.querySelectorAll('input, select, textarea'));
	const emails = Array.from(formToValidate.querySelectorAll('input[type="email"]'));

	const isValid = inputs.reduce((isAllValid, el) => {
		const input = el;
		let filesInfo = true;
	
		if (input.getAttribute('type') === 'file' && input.hasAttribute('required')) {
			filesInfo = getFileInfos(input);
		}

		let isCurValid = el.checkValidity();

		if (isCurValid && input.classList.contains('error')) {
			input.classList.remove('error');
		}

		if (!isCurValid) {
			if ((input.value === '' || !input.checked) && input.getAttribute('required') !== null) {
				input.classList.add('error');
				errorMessage.innerHTML = ns.warrantyFormGenericError;
				slideDown(errorMessage);
			} else if (input.getAttribute('type') === 'email') {
				input.classList.add('error');
				errorMessage.innerHTML = ns.warrantyFormEmailError;
				slideDown(errorMessage);
			}
		}

		if (input.getAttribute('type') === 'tel' && !input.value.match(/^(?=.*?[1-9])[0-9()-]+$/)) {
			input.classList.add('error');
			errorMessage.innerHTML = ns.warrantyFormGenericError;
			slideDown(errorMessage);
			
			isCurValid = false;
		}

		if (input.getAttribute('type') === 'file' && !filesInfo) {
			input.classList.add('error');
			if (!input.files[0]) {
				errorMessage.innerHTML = ns.warrantyFormEmptyFileError;
			} else {
				errorMessage.innerHTML = ns.warrantyFormFileError;
			}
			slideDown(errorMessage);
			isCurValid = false;
		}

		return isAllValid && isCurValid;
	}, true);


	const validSameEmail = emails.reduce((acc, curr) => {
		if (acc.value !== curr.value) {
			acc.classList.add('error');
			curr.classList.add('error');
			errorMessage.innerHTML = ns.warrantyFormEmailConfirmationError;
			slideDown(errorMessage);
		}
		return acc.value === curr.value;
	});

	if (isValid && validSameEmail) {
		sendEmail(formToValidate);
	}
}

function sendEmail(form) {
	const formToValidate = form;
	const successMessage = document.querySelector('.success-message');
	const errorMessage = formToValidate.querySelector('.error-message');
	slideUp(errorMessage);
	
	const formData = new FormData(); // Currently empty
	const inputs = formToValidate.querySelectorAll('input');
	const selects = formToValidate.querySelectorAll('select');
	const textArea = formToValidate.querySelectorAll('textarea');
	const cvField = formToValidate.querySelector('[data-cv-file]');

	inputs.forEach((field) => {
		formData.append(field.name, field.value);
	});

	if (selects.length > 0) {
		selects.forEach((field) => {
			formData.append(field.name, field.value);
		});
	}

	if (textArea.length > 0) {
		textArea.forEach((field) => {
			formData.append(field.name, field.value);
		});
	}

	if (cvField) {
		const cvFile = cvField.files[0];
		const cvFileName = cvField.value.split('\\').pop();
		formData.append('careers[cv]', cvFile, cvFileName);
	}

	reqwest({
		url: formToValidate.getAttribute('action'),
		data: formData,
		processData: !(formData instanceof FormData),
		method: 'POST',
		type: 'POST',
		success: (resp) => {
			// console.log(resp);
			const formWrapper = document.querySelector('[data-form-wrapper]');
			const wrapperHeight = formWrapper.clientHeight;
			const formHeight = formToValidate.clientHeight;
			let newHeight;
			
			const formName = formToValidate.getAttribute('data-form-tovalidate');
			fbq('trackCustom', 'Lead', {
				form_name: `${formName}`
			});
			scrollToElem(formWrapper, { 
				offset: -200, 
				onComplete: () => {
					const tl = new TimelineMax();
					tl.set(formWrapper, { height: `${wrapperHeight}px` });
					tl.to(formToValidate, 0.5, { opacity: 0, ease: Expo.easeOut });
					tl.add(() => {
						slideUp(formToValidate);
						formToValidate.reset();
					});
					tl.to(successMessage, 0.3, { height: successMessage.scrollHeight });
					tl.add(() => {
						newHeight = (wrapperHeight - formHeight) + successMessage.clientHeight;
						TweenMax.to(formWrapper, 0.5, { 
							height: `${newHeight}px`, 
							ease: Expo.easeOut, 
							onComplete: () => {
								TweenMax.set(formWrapper, { clearProps: 'all' });
							},
						});
					});
				},
			});
		},
	}).fail(() => {
		errorMessage.innerHTML = ns.warrantyFormGenericError;
		slideDown(errorMessage);
	});
}

function spontaneous(e) {
	const clicked = e.currentTarget;
	const clickedVal = clicked.value;
	const selected = clicked.querySelector(`[value='${clickedVal}']`);
	const ddCategory = document.querySelector('[data-spontaneous-cat]');
			
	if (selected.hasAttribute('data-spontaneous')) {
		ddCategory.classList.add('show');
	} else {
		ddCategory.classList.remove('show');
		ddCategory.querySelector('[select]').value = '';
	}
}

function changeBtnLabel(e) {
	const input = e.currentTarget;
	const label = input.parentNode.querySelector('label');
	const span = label.querySelector('span');
	const prevLabel = label.getAttribute('data-file-label');
	let fileName = input.value;

	if (fileName !== '') {
		if (input.classList.contains('error')) input.classList.remove('error');
		fileName = fileName.split('\\').pop();
		span.innerHTML = fileName;
	} else {
		span.innerHTML = prevLabel;
	}
}

export default {
	init() {
		const formToValidate = document.querySelector('[data-form-tovalidate]');
		if (!formToValidate || formToValidate.length === 0) return;

		const inputFile = formToValidate.querySelectorAll('input[type=file]');
		if (inputFile) {
			Array.from(inputFile).forEach((curr) => {
				curr.addEventListener('change', changeBtnLabel);
			});
		}

		const ddOpening = document.querySelector('[data-opening]');

		if (ddOpening) ddOpening.addEventListener('change', spontaneous);

		formToValidate.addEventListener('submit', validForm);
	},
};
