export default function getObject() {
	return {
		category: 0,
		style: [],
		finish: [],
		detail: [],
		range: [],
		availability: [],
		minimum: '',
		maximum: '',
		filterBy: '',
		keyword: '',
	};
}
