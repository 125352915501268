'format es6';
'use strict';

import { isMobile } from './utils/isMobile';
import { slideToggle } from './utils/slide';

const PARENT_OPENED_CLASS = 'opened';
const MENU_HIDDEN_CLASS = 'hidden';
const MENU_STICKY_CLASS = 'sticky';
const MENU_OPENED_CLASS = 'menu-opened';
const STICKY_SENSIBILITY = 10;
const STICKY_SCROLL_TRESHOLD = 0;

let menu;
let parentMenuItems;
let mobileMenuButton;
let lastScrollValue = 0;
let wasMobile = isMobile();

function setupDomReferences() {
	menu = document.querySelector('[data-main-menu]');
	parentMenuItems = menu.querySelectorAll('[data-parent-item]');
	mobileMenuButton = menu.querySelector('[data-menu-btn]');
}

function closeParentMenus() {
	parentMenuItems.forEach((parent) => {
		parent.classList.remove(PARENT_OPENED_CLASS);
	});
}

function removeMobileMenuClasses() {
	menu.classList.remove(MENU_OPENED_CLASS);
	document.body.classList.remove(MENU_OPENED_CLASS);
	parentMenuItems.forEach((parent) => {
		const subnav = Array.from(parent.children).filter((el) => {
			return el.tagName === 'UL';
		})[0];

		if (subnav) {
			subnav.style.height = '';
		}
	});
}

function handleParentClick(e) {
	const clicked = e.currentTarget;

	if (isMobile()) {
		const subnav = Array.from(clicked.children).filter((el) => {
			return el.hasAttribute('data-submenu');
		})[0];
		console.log(subnav);

		if (subnav) {
			slideToggle(subnav);
		}
	} else {
		const currOpen = menu.querySelector('[data-parent-item].opened');
		if (clicked !== currOpen) closeParentMenus();
		clicked.classList.toggle(PARENT_OPENED_CLASS);
	}
}

function handleScroll() {
	const delta = window.scrollY - lastScrollValue;

	if (window.scrollY > STICKY_SCROLL_TRESHOLD) {
		if (!menu.classList.contains(MENU_OPENED_CLASS)) {
			if (delta <= -STICKY_SENSIBILITY) {
				menu.classList.remove(MENU_HIDDEN_CLASS);
				menu.classList.add(MENU_STICKY_CLASS);
			} else if (
				(menu.classList.contains(MENU_STICKY_CLASS) && delta >= STICKY_SENSIBILITY) 
				|| !menu.classList.contains(MENU_STICKY_CLASS)
			) {
				menu.classList.add(MENU_HIDDEN_CLASS);
				menu.classList.remove(MENU_STICKY_CLASS);
	
				closeParentMenus();
			}
		}
	} else if (window.scrollY === 0) {
		menu.classList.remove(MENU_HIDDEN_CLASS, MENU_STICKY_CLASS);
	}

	lastScrollValue = window.scrollY;
}

function handleMenuButtonClick() {
	menu.classList.toggle(MENU_OPENED_CLASS);
	document.body.classList.toggle(MENU_OPENED_CLASS);
}

function handleResize() {
	if (wasMobile && !isMobile()) {
		removeMobileMenuClasses();
	} else if (!wasMobile && isMobile()) {
		closeParentMenus();
	}

	wasMobile = isMobile();
}

export default {

	init() {
		setupDomReferences();
		
		parentMenuItems.forEach((parent) => {
			parent.addEventListener('click', handleParentClick);
		});

		mobileMenuButton.addEventListener('click', handleMenuButtonClick);

		window.addEventListener('scroll', handleScroll);
		window.addEventListener('resize', handleResize);
	},
};
