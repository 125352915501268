
import { TimelineMax, TweenMax, Expo } from 'gsap/TweenMax';
import ns from './NameSpace';
import ProductReqwest from './ProductReqwest'; // eslint-disable-line
import BuyButton from './BuyButton';

let hearts = [];
const menuHeart = document.querySelector('[data-favorite-lnk]');

function addLikedProduct(e) {
	const liked = e.currentTarget;
	const likedId = liked.getAttribute('data-like-product');

	const ls = JSON.parse(localStorage.getItem('likedProduct'));

	const product = [];

	if (ls) {
		Array.from(ls).forEach((curr) => {
			product.push(curr);
		});
	}

	const index = product.indexOf(likedId);
	if (index === -1) {
		product.push(likedId);
		liked.classList.add('liked');
	} else {
		product.splice(index, 1);
		liked.classList.remove('liked');
	}

	setMenuLikeClass(product.length);

	localStorage.setItem('likedProduct', JSON.stringify(product));
}

export function setMenuLikeClass(hasLikedProducts) {
	const action = hasLikedProducts ? 'add' : 'remove';
	menuHeart.classList[action]('full');
}

export default {
	init() {
		const url = ns.favoriteContainerPage;
		const favoriteContainer = document.querySelector('[data-favorite-container]');
		if (favoriteContainer) {
			const ls = this.getLocalStorage();

			if (ls && ls.length > 0) {
				const data = { favorite: { ids: ls } };	
				const loadedData = ProductReqwest.loadContent(url, data, ['[data-product-content]'], null, null, false);
	
				loadedData.then(() => {
					this.handleEvents();
					BuyButton.init();
				});
			} else {
				const container = favoriteContainer.querySelector('[data-product-content]');
				const noResult = favoriteContainer.querySelector('[data-product-noresult]');
				const loading = favoriteContainer.querySelector('[data-loading]');
				const containerHeight = loading.clientHeight;

				setTimeout(() => {
					const tl = new TimelineMax();
					tl.set(container, { height: `${containerHeight}px` });
					tl.to(loading, 0.8, { opacity: 0, ease: Expo.easeOut });
					tl.add(() => {
						loading.classList.add('remove');
					});
					tl.add(() => {
						TweenMax.set(noResult, { opacity: 0 });
						noResult.classList.add('visible');
					});
					tl.to(noResult, 0.8, { opacity: 1, ease: Expo.easeOut });
				}, 2000);
			}

		} else {
			this.handleEvents();
		}
	},

	setLikedClass() {
		const ls = this.getLocalStorage();

		if (ls) {
			setMenuLikeClass(ls.length);

			Array.from(ls).forEach((curr) => {
				const elem = document.querySelector(`[data-like-product="${curr}"]`);
				// console.log(elem);
				if (elem) elem.classList.add('liked');
			});
		}
	},

	handleEvents() {
		// remove all event and add event (mostly used with ajax call)
		if (hearts.length > 0) {
			Array.from(hearts).forEach((heart) => {
				heart.removeEventListener('click', addLikedProduct);
			});
		}

		hearts = document.querySelectorAll('[data-like-product]');

		Array.from(hearts).forEach((heart) => {
			heart.addEventListener('click', addLikedProduct);
		});

		this.setLikedClass();
	},

	getLocalStorage() {
		return JSON.parse(localStorage.getItem('likedProduct'));
	},
};
