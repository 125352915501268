
import ns from './NameSpace';

export default {
	init() {
		if (
			window.location.href === 'https://shop.artika.com/' ||
			window.location.href === 'https://shop.artika.com/fr/' ||
			window.location.href === 'https://shop.artika.com/fr' ||
			window.location.href === 'https://shop.artika.com/en' ||
			window.location.href === 'https://shop.artika.com/en/'
		) {
			window.location.href = `//artika.com/${ns.lang}`;
		}

		const shopBackLinks = document.querySelectorAll('a[href*="/session/back/"]');
		Array.from(shopBackLinks).forEach(el => {
			el.setAttribute('href', ns.productPageLnk);
		});
	},
};
