function removeError(e) {
	const el = e.currentTarget;
	
	if (el && el.classList.contains('error')) {
		el.classList.remove('error');
	}
}

function handleInputsChange(e, isNode = false) {
	const el = isNode ? e : e.currentTarget;

	if (el.value === '') {
		el.classList.add('empty');

		const label = el.parentNode.querySelector('label');
		if (label) {
			label.classList.remove('visible');
		}
	} else {
		el.classList.remove('empty');

		const label = el.parentNode.querySelector('label');
		if (label) {
			label.classList.add('visible');
		}
	}
	
	removeError(e);
}

function handleSelectsChange(e) {
	const el = e.currentTarget;
	const label = el.parentNode.querySelector('label');
	if (el.value !== '0') {
		el.classList.remove('empty');
		if (label) label.classList.add('visible');
	} else {
		el.classList.add('empty');
		if (label) label.classList.remove('visible');
	}
}

function handleNumbersChange(e) {
	const el = e.currentTarget;
	if (el.value > 0) {
		el.classList.remove('empty');
	} else {
		el.classList.add('empty');
	}
}

export default {
	init() {
		const inputFields = document.querySelectorAll('input[type=text], input[type=tel], input[type=email], input[type=password], textarea');
		const selects = document.querySelectorAll('select');
		const numbers = document.querySelectorAll('input[type=number]');
		
		selects.forEach((input) => {
			input.removeEventListener('change', handleSelectsChange);
			input.addEventListener('change', handleSelectsChange);
		});
		
		numbers.forEach((input) => {
			input.removeEventListener('change', handleNumbersChange);
			input.addEventListener('change', handleNumbersChange);
		});

		inputFields.forEach((input) => {
			const inputValue = input.value;
			
			if (inputValue !== null && inputValue !== '') {
				handleInputsChange(input, true);
			}

			['keypress', 'blur', 'change', 'input'].forEach(event => {
				input.removeEventListener(event, handleInputsChange);
				input.addEventListener(event, handleInputsChange);
			});
		});
	},
};
