
'format es6';
'use strict';

import ns from './NameSpace';
import getSearchObject from './SearchObject';
import { isMobile } from './utils/isMobile';
import { getLocation } from './utils/getLocation';

import RangeSlider from './RangeSlider';

import ProductReqwest from './ProductReqwest';
import HandleSidebarMobile from './HandleSidebarMobile';
import Like from './Like';
import StickySearchBtn from './StickySearchBtn';
import FormValidation from './FormValidation';
import Cart from './Cart';


let searchParams = getSearchObject();

let productCategory;
let paging = [];
const currUrl = getLocation();
let steps = 1;

const mobile = isMobile();

// objet pour le selected product
const selectedProduct = {
	productID: '',
	productSku: '',
};

// la function qui s'occupe de changer les quantité dans la sidebar des prouits, utile seulement si on arrive par une categorie selectionner dans le menu
// function changeFilterQty(filters) {
// 	filters.forEach((newFilter) => {
// 		if (newFilter.getAttribute('type') == null) {
// 			const currNewFilterGroup = newFilter.getAttribute('data-sidebar-filter');
// 			const oldFilter = document.querySelector(`[data-sidebar-filter=${currNewFilterGroup}]`);
// 			const oldCheckboxes = oldFilter.querySelectorAll('[data-filter-number]');
// 			oldCheckboxes.forEach((oldCheckbox) => {
// 				const oldType = oldCheckbox.getAttribute('for');
// 				const newTypeNumber = newFilter.querySelector(`[for=${oldType}]`).getAttribute('data-filter-number');
// 				const newNumber = newTypeNumber || 0;
// 				oldCheckbox.querySelector('[data-number]').innerHTML = newNumber;
// 			});
// 		}
// 	});
// }

function setEventSticky(event, replaceSidebar) {
	const fcn = event ? 'addEventListener' : 'removeEventListener';
	const stickyBtn = document.querySelector('[data-sticky-button-container]');
	if (stickyBtn && (replaceSidebar || replaceSidebar === undefined)) {
		window[fcn]('scroll', StickySearchBtn.trackBtn);
		if (event) StickySearchBtn.setBtn();
		StickySearchBtn.trackBtn();
	}
}

export function manageAllEvents(isAdd, replaceSidebar = false) {
	setEventBtn(isAdd, replaceSidebar);
	setEventSticky(isAdd, replaceSidebar);
	FormValidation.init();
}

// le mega call ajax
function productCall(directLink, data = null, replaceSidebar = false, replaceIntro = false, replaceSidebarMobile = false, initializeContent = null) {
	const elementsToReplace = ['[data-product-content]'];
	
	ProductReqwest.loading(elementsToReplace);

	const url = directLink || currUrl;
	manageAllEvents(false, replaceSidebar);
	
	if (replaceSidebar) elementsToReplace.push('[data-sidebar-inner]');
	if (replaceIntro) elementsToReplace.push('[data-upper-filters-content]');
	if (replaceSidebarMobile) elementsToReplace.push('[data-handle-filters]');

	const onLoaded = ProductReqwest.loadContent(url, data, elementsToReplace, replaceSidebar, (response) => {
		if (initializeContent) {
			initializeContent(response);
		}
	});

	return onLoaded.then(() => {
		setPaging();
		Like.handleEvents();
	});
}

// event qui applique les categories
function categoryFilter(e) {
	const currProduct = e.currentTarget;
	searchParams.category = currProduct.value;

	const filtersForm = document.querySelector('[data-filter-form]');
	handleCheckedFilter(filtersForm);
}

// event qui s'occupe d'appliquer tout les filtres coché dans la sidebar
function handleCheckedFilter(elem) {
	if (elem) {
		const filters = elem.querySelectorAll('[data-sidebar-filter]');
	
		filters.forEach((curr) => {
			const filter = curr.getAttribute('data-sidebar-filter');
			const values = curr.querySelectorAll('input');
			// console.log(curr);
			
			if (curr.getAttribute('type') !== null) {
				// il a un type, donc c'est un prix
				const settedPrice = document.querySelector(`[data-${filter}-set]`).getAttribute(`data-${filter}-set`);
				if (settedPrice !== '') {
					searchParams[filter] = curr.value;
				}
			} else {
				searchParams[filter] = [];
				// console.log(searchParams);
				values.forEach((value) => {
					const type = value.getAttribute('type');
					if (type === 'checkbox' && value.checked) {
						const inputValue = value.value;
						searchParams[filter].push(inputValue);
					}
				});
			}
		});
	}

	fbq('track', 'Search'); // eslint-disable-line

	productCall(null, { search: searchParams }, false, false, false);
}

// event appeler par le bouton appliquer/apply, des filtres dans la sidebar
function validFilters(e) {
	e.preventDefault();

	if (mobile) searchKeyword();
	const elem = e.currentTarget;
	handleCheckedFilter(elem);
}

// applique les filter by dans l'objet searchParams
function filterBy(e) {
	const selectedFilter = e.currentTarget;
	searchParams.filterBy = selectedFilter.value;

	const filtersForm = document.querySelector('[data-filter-form]');
	if (filtersForm) {
		handleCheckedFilter(filtersForm);
	} else {
		productCall(null, { search: searchParams });
	}
}

//function reset et call les produits de nouveau
function resetAll(e) {
	e.preventDefault();
	document.querySelector('[data-filter-form]').reset();
	RangeSlider.destroy();

	searchParams = getSearchObject();
	productCall(null, { search: searchParams });
}

// applique le search par keyword dans l'objet searchParams et applique les autres filtres qui auraient pu être cocher
function searchKeyword(e) {
	if (e) e.preventDefault();
	const search = document.querySelector('[data-keyword]');
	const { value } = search;

	searchParams.keyword = value;

	const filtersForm = document.querySelector('[data-filter-form]');
	if (!mobile && filtersForm) {
		handleCheckedFilter(filtersForm);
	} else if (!mobile) {
		productCall(null, { search: searchParams });
	}
}

//function pour handle le changement de page via ajax
function handlePaging(e) {
	e.preventDefault();
	const clickedPage = e.currentTarget;
	const clickedLink = clickedPage.getAttribute('href');
	productCall(clickedLink);
}

//function pour add ou remove les listener du paging
function setPaging() {
	if (paging.length > 0) {
		Array.from(paging).forEach((page) => {
			page.removeEventListener('click', handlePaging);
		});
	}
	paging = document.querySelectorAll('[data-paging]');
	Array.from(paging).forEach((page) => {
		page.addEventListener('click', handlePaging);
	});
}

//event resize pour la sidebar des filters, le sticky et le menu filter mobile
function onResize() {
	StickySearchBtn.trackBtn();
	HandleSidebarMobile.handleEvent();
}

function setEventBtn(event, replaceSidebar) {
	const chooseBtns = document.querySelectorAll('[data-choose-product]');
	const fcn = event ? 'addEventListener' : 'removeEventListener';

	if (chooseBtns) {
		Array.from(chooseBtns).forEach((curr) => {
			curr[fcn]('click', updateSelectedProduct);
		});
	}

	// const lastStepBtns = document.querySelectorAll('[data-product-part-last-step]');
	// if (lastStepBtns) {
	// 	lastStepBtns.forEach((btn) => {
	// 		btn[fcn]('click', gotoForm);
	// 	});
	// }
	
	const backBtn = Array.from(document.querySelectorAll('[data-cs-back]'));

	if (backBtn) {
		backBtn.forEach((back) => {
			back[fcn]('click', (e) => {
				e.preventDefault();
				const step = e.currentTarget.getAttribute('data-cs-back');
				const link = back.getAttribute('href');
	
				productCall(link, { product: selectedProduct }, true, true, true, (content) => {
					HandleSidebarMobile.handleEvent(content, true);
				});
	
				steps = step;
				animSteps();
			});
		});
	}

	productCategory = document.querySelector('[data-product-category-select]');
	if (productCategory) productCategory[fcn]('change', categoryFilter);

	const form = document.querySelector('[data-sidebar-filters] [data-filter-form]');
	if (form) {
		if ((replaceSidebar || replaceSidebar === undefined) && event) {
			form.reset();
		}
		form[fcn]('submit', validFilters);
	}

	const resetBtn = document.querySelector('[data-reset]');
	if (resetBtn) {
		resetBtn[fcn]('click', resetAll);
	}

	const searchBox = document.querySelector('[data-search-box]');
	if (searchBox) {
		if (replaceSidebar || replaceSidebar === undefined) {
			searchBox.reset();
		}
		searchBox[fcn]('submit', searchKeyword);
	}

	const filtersBy = document.querySelector('[data-filter-by]');
	if (filtersBy) {
		filtersBy[fcn]('change', filterBy);
	}

	const rangeSlider = document.querySelector('[data-slider-container]');
	if (rangeSlider) {
		if (event) {
			RangeSlider.init();
		} else {
			RangeSlider.destroy();
		}
	}
}

//function pour selectionner un produit via warranty-part-choice-step1
function updateSelectedProduct(e) {
	e.preventDefault();
	const clickedProductID = e.currentTarget.getAttribute('data-choose-product');
	const clickedProductSku = e.currentTarget.getAttribute('data-product-sku');
	const clickedLink = ns.warrantyLastStepPage;

	if (clickedProductID) {
		selectedProduct.productID = clickedProductID;
		selectedProduct.productSku = clickedProductSku;
	}
	
	const selectedProductData = { product: selectedProduct };
	
	productCall(clickedLink, selectedProductData, true, true, true, (content) => {
		const skuInput = content.querySelector('[data-product-id]');
		if (selectedProduct.productSku === '0' || selectedProduct.productSku === '') {
			skuInput.removeAttribute('required');
			skuInput.removeAttribute('readonly');

			//remove * from product name field
			const inputId = skuInput.getAttribute('id');
			const label = content.querySelector(`label[for=${inputId}]`);
			const newLabel = label.innerHTML.replace('*', '');
			const newPlaceholder = newLabel.replace(/(<([^>]+)>)/ig, '');
			label.innerHTML = newLabel;
			label.setAttribute('data-placeholder', newPlaceholder);
		} else {
			skuInput.value = selectedProduct.productSku;
		}

		steps = 2;
		animSteps();
	});
}

function animSteps() {
	const allStepsIndicator = Array.from(document.querySelectorAll('[data-steps]'));
	allStepsIndicator.forEach((step) => {
		const attr = step.getAttribute('data-steps');
		if (attr === String(steps)) {
			step.classList.add('active');
		} else {
			step.classList.remove('active');
		}
	});
}

export default {
	init() {
		const sidebar = document.querySelector('[data-sidebar-filters]');
		if (!sidebar) return;

		setEventBtn(true);

		setPaging();

		StickySearchBtn.setBtn();
		window.addEventListener('resize', onResize);
		onResize();

		window.addEventListener('scroll', StickySearchBtn.trackBtn);
		StickySearchBtn.trackBtn();
	},
};
