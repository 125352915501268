
import { TweenMax, Expo } from 'gsap/TweenMax';

let mobileState;

const dom = {};

export default {
	handleEvent(container, isAdd) {
		const doc = container || document;

		dom.filtersHandler = doc.querySelectorAll('[data-handle-filters]');
		if (!dom.filtersHandler) return;
		
		dom.body = document.querySelector('body');
		dom.upperFiltersContainer = doc.querySelector('[data-upper-filters-container]');
		dom.upperFilters = doc.querySelector('[data-upper-filters]');
		dom.formParent = doc.querySelector('[data-insert-parent]');
		dom.insertBefore = doc.querySelector('[data-insert-before]');
		
		dom.btn = doc.querySelector('[data-filter-btn-container]');
		dom.btnsContainer = doc.querySelector('[data-sticky-button-container]');
		
		if (window.matchMedia('(max-width: 991px)').matches && (!mobileState | isAdd)) {
			if (dom.btn && dom.btnsContainer) {
				TweenMax.set(dom.btn, { clearProps: 'all' });
				TweenMax.set(dom.btnsContainer, { clearProps: 'all' });
				dom.btn.classList.remove('sticky');
			}

			mobileState = true;
			if (dom.upperFiltersContainer && dom.upperFilters) {
				const upperFilterParent = dom.upperFilters.parentNode.removeChild(dom.upperFilters);
				dom.formParent.insertBefore(upperFilterParent, dom.insertBefore);
			}
	
			Array.from(dom.filtersHandler).forEach((curr) => {
				curr.addEventListener('click', this.handleFilter);
			});
		} else if (window.matchMedia('(min-width: 991px)').matches && mobileState) {
			mobileState = false;
			if (dom.upperFiltersContainer && dom.upperFilters) {
				const upperFilterParent = dom.upperFilters.parentNode.removeChild(dom.upperFilters);
				dom.upperFiltersContainer.appendChild(upperFilterParent);
			}

			dom.body.classList.remove('opened');

			Array.from(dom.filtersHandler).forEach((curr) => {
				curr.removeEventListener('click', this.handleFilter);
			});
		}
	},

	handleFilter(e) {
		const event = e ? e.currentTarget.getAttribute('data-handle-filters') : 'close';
	
		dom.target = document.querySelector('[data-sidebar-trigger]');
	
		let top;
		let bottom;
	
		if (event === 'open') {
			top = '0px';
			bottom = '0px';
		} else {
			top = '100%';
			bottom = '-100px';
		}
	
		dom.body.classList.toggle('opened');
		TweenMax.to(dom.target, 0.8, { top, ease: Expo.easeOut });
		dom.target.classList.toggle('opened');
		if (dom.btn) TweenMax.to(dom.btn, 0.8, { bottom, ease: Expo.easeOut });
	},
};
