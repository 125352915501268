'format es6';
'use strict';

import $ from 'jquery';
import Slick from 'slick-carousel';
import imagesLoaded from 'imagesloaded';
import Promise from 'bluebird';

let bigSlider;
let smallSlider;

export default {
	init() {
		bigSlider = document.querySelector('[data-big-slider]');
		smallSlider = document.querySelector('[data-small-slider]');
		if (!bigSlider && !smallSlider) return;

		const loadedImages = new Promise((resolve) => {
			imagesLoaded(document.body, { background: true }, resolve);
		});

		loadedImages.then(() => {
			const children = smallSlider.querySelectorAll('img').length;
			$(smallSlider).slick({
				slidesToShow: Math.min(4, children),
				slidesToScroll: 1,
				asNavFor: bigSlider,
				dots: false,
				arrows: true,
				rows: 0,
				focusOnSelect: true,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 3,
						},
					},
				],
			});
	
			$(bigSlider).slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				rows: 0,
				arrows: false,
				fade: true,
				asNavFor: smallSlider,
			});

		});
	},
};
