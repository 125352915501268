import ns from './NameSpace';
import $ from 'jquery';
import { slideUp, slideDown } from './utils/slide';


function addToList(e) {
	e.preventDefault();
	const form = e.currentTarget;
	const listUrl = ns.lang === 'en' ? ns.newsletterEn : ns.newsletterFr;

	$.ajax({
		url: listUrl,
		method: 'post',
		data: $(form).serialize(),
		type: 'json',
		dataType: 'jsonp',
		jsonp: 'c',
		success: () => {
			const message = ns.newsletterSuccess;
			const messageDiv = document.querySelector('[data-newsletter-response]');

			slideUp(form);
			messageDiv.innerHTML = message;
			slideDown(messageDiv);
		},
		error: (data) => {
			const message = ns.newsletterError;
		},
	});
}

export default {
	init() {
		const newsletterForm = document.querySelector('[data-newsletter]');
		newsletterForm.addEventListener('submit', addToList);
	},
}
