import ns from './NameSpace';
import { updateCartIndicator } from './Cart';
import { menuCartListener } from './BuyButton'; // eslint-disable-line

const qtyInput = `
	<div class="field number-field {{data.classes.product.quantity}} {{data.quantityClass}}" data-number-field data-element="product.quantity">
		<div class="{{data.classes.product.quantityButton}} {{data.classes.product.quantityIncrement}} up" data-element="product.quantityIncrement"></div>

		<input class="{{data.classes.product.quantityInput}}" type="number" min="0" aria-label="Quantity" value="{{data.selectedQuantity}}" data-element="product.quantityInput">

		<div class="{{data.classes.product.quantityButton}} {{data.classes.product.quantityDecrement}} down" data-element="product.quantityDecrement"></div>
	</div>
`;

const qtyInputCart = `
	<div class="field number-field {{data.classes.lineItem.quantity}}" data-number-field data-element="lineItem.quantity">
		<div class="{{data.classes.lineItem.quantityButton}} {{data.classes.lineItem.quantityIncrement}} up" data-line-item-id="{{data.id}}" data-element="lineItem.quantityIncrement"></div>

		<input class="{{data.classes.lineItem.quantityInput}}" type="number" min="0" aria-label="Quantity" value="{{data.quantity}}" data-line-item-id="{{data.id}}" data-element="lineItem.quantityInput">

		<div class="{{data.classes.lineItem.quantityButton}} {{data.classes.lineItem.quantityDecrement}} down" data-line-item-id="{{data.id}}" data-element="lineItem.quantityDecrement"></div>
	</div>
`;

export const qty = `<div class="form quantity-input-wrapper">
	<label for="qty">${ns.buyButton.productComponent.quantity} :</label>
	${qtyInput}
</div>`;

const lineItemQty = `<div class="form quantity-input-wrapper">
	${qtyInputCart}
</div>`;

const lineItemPriceTemplate = `
	<div class="cart-line-item">
		<div class="{{data.classes.lineItem.itemTitle}} cart-line-item-title" data-element="lineItem.itemTitle">{{data.title}}</div>
		<div class="{{data.classes.lineItem.price}} cart-line-item-price" data-element="lineItem.price">{{data.formattedPrice}}</div>
	</div>
`;


export const cartOpt = {
	iframe: false,
	popup: false, // permet d'ouvrir le checkout dans la même window
	startOpen: false,
	events: {
		afterInit: (cart) => {
			menuCartListener();
		},
	},
	classes: {
		cart: 'shopify-buy__cart cart-content',
		header: 'shopify-buy__cart__header cart-header',
		title: 'shopify-buy__cart__title cart-title',
		lineItems: 'shopify-buy__cart-items cart-item',
		footer: 'shopify-buy__cart-bottom cart-total-info',
		button: 'button-flat-bordered shopify-buy__btn shopify-buy__btn--cart-checkout',
		close: 'shopify-buy__btn--close cart-close-btn',
	},
	text: {
		title: ns.buyButton.cartComponent.cartTitle,
		empty: ns.buyButton.cartComponent.emptyCart,
		button: ns.buyButton.cartComponent.checkoutBtn,
		total: ns.buyButton.cartComponent.checkoutTotal,
		currency: ns.buyButton.cartComponent.checkoutCurrencyCan,
		notice: '',
	},
	DOMEvents: {
		'click .cart-close-btn': () => {
			document.querySelector('body').classList.remove('menu-opened');
		},
	},
};

export const lineItemOpt = {
	contents: {
		image: true,
		variantTitle: false,
		title: false,
	},
	templates: {
		price: lineItemPriceTemplate,
		image: '<img class="cart-img" src="{{data.lineItemImage}}" alt="{{data.variantTitle}}" data-element="lineItem.image"/>',
		quantity: lineItemQty,
	},
};

function cartUpdater() {
	const count = document.querySelector('.cart-count-shopify').innerHTML;
	updateCartIndicator(Number(count));
}

export const toggleOpt = {
	iframe: false,
	events: {
		afterInit: () => {
			cartUpdater();
			const toggle = document.querySelector('.cart-toggler');
			// we use MutationObserver to force our cart number to update when shopify update his component.
			const config = { childList: true, subtree: true, characterData: true };
			const observer = new MutationObserver(cartUpdater);
			observer.observe(toggle, config);
		},
	},
	classes: {
		toggle: 'shopify-buy__cart-toggle cart-toggler',
		count: 'shopify-buy__cart-toggle__count cart-count-shopify',
	},
};
