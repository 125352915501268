'format es6';
'use strict';

import { scrollToElem } from './utils/scrollTo';

function onAnchorClick(e) {
	e.preventDefault();
	
	const targetEl = e.currentTarget;
	const targetHref = targetEl.getAttribute('href').substring(1);
	const target = document.getElementById(targetHref);

	if (target) {
		scrollToElem(target, { time: 0.8, offset: -100 });
	}
}

export default {
	init() {
		const anchorLinks = document.querySelectorAll('a[href^="#"]');

		if (anchorLinks) {
			anchorLinks.forEach((curr) => {
				curr.addEventListener('click', onAnchorClick);
			});
		}
	},
};
