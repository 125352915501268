'format es6';
'use strict';

import $ from 'jquery';
import Slick from 'slick-carousel';

let sliders;
let isMobile;

function initSlider() {
	if (window.matchMedia('(max-width: 991px)').matches && !isMobile) {
		isMobile = true;

		sliders.forEach((slider) => {
			$(slider).slick({
				autoplay: false,
				rows: 0,
				slidesToShow: 3,
				speed: 800,
				dots: false,
				arrows: false,
				responsive: [
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 1,
							centerMode: true,
						},
					},
				],
			});
		});
		
	} else if (window.matchMedia('(min-width: 992px)').matches && isMobile) {
		sliders.forEach((slider) => {
			$(slider).slick('unslick');
		});
		isMobile = false;
	}

	if (window.matchMedia('(max-width: 991px)').matches) {
		sliders.forEach((slider) => {
			$(slider)[0].slick.refresh();
		});
	}
}

export default {
	init() {
		sliders = document.querySelectorAll('[data-slider]');
		if (!sliders) return;

		window.addEventListener('resize', initSlider);
		initSlider();
	},
};
