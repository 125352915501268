'format es6';
'use strict';

import reqwest from 'reqwest';
import Promise from 'bluebird';
import { TweenMax, Expo } from 'gsap/TweenMax';
import { scrollToElem } from './utils/scrollTo';
import HandleSidebarMobile from './HandleSidebarMobile';
import Forms from './Forms';
import { manageAllEvents } from './SidebarReqwest'; // eslint-disable-line
import StickySidebar from './StickySidebar';

export default {
	loadContent(url, data, selectors = ['[data-product-content]'], replaceSidebar, initializeContent = null, scrollTo = true) {
		const filters = document.querySelector('[data-sidebar-trigger]');
		if (filters && filters.classList.contains('opened')) {
			HandleSidebarMobile.handleFilter();
		}

		return new Promise((resolve) => {
			reqwest({
				url,
				data,
				method: 'get',
			}).then((resp) => {
				const response = document.createElement('div');
				response.innerHTML = resp;
				// console.log(response.innerHTML);
				if (initializeContent) initializeContent(response);

				const animations = selectors.map((selector, i) => {
					const container = document.querySelector(selector);
					return this.loadAnimation(response, container, selector, scrollTo, () => {
						if (selectors.length === (i + 1)) {
							manageAllEvents(true, replaceSidebar);
						}
					}); 
				});

				return resolve(Promise.all(animations));
			});
		});
	},

	loadAnimation(response, content, selector, scrollTo, onBeforeAnimateIn = null) {
		return new Promise((resolve) => {
			const newContent = response.querySelector(selector);
			const container = content.parentNode;
			const containerHeight = container.clientHeight;

			if (!newContent) {
				TweenMax.to(container, 0.8, { 
					opacity: 0, 
					ease: Expo.easeOut, 
					onComplete: () => {
						TweenMax.fromTo(container, 0.8, { height: `${containerHeight}px` }, { height: '0px' });
						if (onBeforeAnimateIn) onBeforeAnimateIn();
						resolve();
					},
				});
				return;
			}

			let newHeight;
			container.style.height = 'auto';
			if (scrollTo) scrollToElem(container, { time: 0.8, offset: -200 });

			TweenMax.to(container, 0.8, { 
				opacity: 0,
				ease: Expo.easeOut,
				onComplete: () => {
					content.replaceWith(newContent);
					const addedContent = container.querySelector(selector);
					Forms.init();
					if (onBeforeAnimateIn) onBeforeAnimateIn();

					newHeight = addedContent.clientHeight;
					const stickySidebar = document.querySelector('[data-sticky-inner]');
					if (stickySidebar) {
						StickySidebar.resetPos();
					}

					TweenMax.staggerFromTo(newContent.querySelectorAll('[data-animated-box]'), 0.6, { opacity: 0 }, { opacity: 1, ease: Expo.easeOut, delay: 1 }, 0.04);
					TweenMax.fromTo(container, 0.8, { height: `${containerHeight}px` }, { height: `${newHeight}px`, opacity: 1 });
					
					TweenMax.delayedCall(1, () => {
						TweenMax.set(container, { clearProps: 'all' });
						resolve();
					});
				},
			});
		});
	},

	loading(elementsToReplace) {
		const content = document.querySelector(elementsToReplace);
		const loadingHTML = `<div class="loading" data-loading>
			<p class="download-text">Nous raffinons votre recherche</p>
			<div class="loading-container">
				<div class="loading-circle"></div>
			</div>
		</div>`;
		content.innerHTML += loadingHTML;
		const loading = document.querySelector('[data-loading]');
		TweenMax.to(loading, 0.5, { opacity: 1, ease: Expo.easeOut });
	},
};
