'format es6';
'use strict';

let videoOverlay;
let player;

function closeVideoPlayer(e) {
	e.preventDefault();

	videoOverlay.classList.remove('opened');
	setTimeout(() => {
		player.innerHTML = '';
	}, 600);
}

function setupYoutube(e) {
	e.preventDefault();
	const clicked = e.currentTarget;
	const ytId = clicked.getAttribute('data-youtube-id');

	const source = `https://www.youtube.com/embed/${ytId}?autoplay=1&rel=0&modestbranding=1&showinfo=0`;
	const html = `<iframe width="100%" height="100%" src="${source}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;
	
	player.innerHTML = html;
	videoOverlay.classList.add('opened');
}

export default {
	init() {	
		const playerBtns = document.querySelectorAll('[data-youtube-id]');
		if (playerBtns.length <= 0) return;
		
		const body = document.querySelector('body');
		const playerDOM = `
			<div class="video-overlay" data-player-container>
				<div class="close" data-close-player></div>
				<div class="player" data-player></div>
			</div>
		`;
		body.insertAdjacentHTML('beforeend', playerDOM);
		videoOverlay = document.querySelector('[data-player-container]');

		player = videoOverlay.querySelector('[data-player]');
		const close = videoOverlay.querySelector('[data-close-player]');		

		close.addEventListener('click', closeVideoPlayer);
		Array.from(playerBtns).forEach((curr) => {
			curr.addEventListener('click', setupYoutube);
		});
	},
};
