const dom = {};

function closeAllPanels() {
	dom.hotspots.forEach(hotspot => hotspot.classList.remove('active'));
	dom.cards.forEach(hotspot => hotspot.classList.remove('active'));
}

function onClickHotspot(e) {
	closeAllPanels();
	if (e.currentTarget) e.currentTarget.classList.add('active');
	e.currentTarget.classList.add('active');

	dom.cards.forEach((card) => {
		// console.log(e.currentTarget.getAttribute('data-hotspot'), card.getAttribute('data-product-card'));
		if (e.currentTarget.getAttribute('data-hotspot') === card.getAttribute('data-product-card')) {
			card.classList.add('active');
		}
	});
}

function onResize() {
	dom.track.style.width = window.innerWidth <= 768 ? dom.image.clientWidth + 'px' : '';
}

function init() {
	dom.container = document.querySelector('[data-hotspot-module]');
	if (!dom.container) return;

	dom.image = dom.container.querySelector('[data-lifestyle-image]');
	dom.track = dom.container.querySelector('[data-hotspot-track]');
	dom.hotspots = Array.from(dom.container.querySelectorAll('[data-hotspot]'));
	dom.cards = Array.from(dom.container.querySelectorAll('[data-product-card]'));
	dom.productsClose = Array.from(dom.container.querySelectorAll('[data-product-close]'));

	dom.hotspots.forEach((hotspot) => {
		hotspot.addEventListener('click', onClickHotspot);
	});

	dom.productsClose.forEach((close) => {
		close.addEventListener('click', closeAllPanels);
	});

	dom.image.addEventListener('load', onResize);
	window.addEventListener('resize', onResize);
	onResize();
}

export default {
	init,
}