
const dom = {};

function init() {
	dom.socials = document.querySelector('[data-socials]');
	if (!dom.socials) return;
	dom.footer = document.querySelector('[data-footer]');
	dom.offset = 50;

	stickySocials();
	
	window.addEventListener('scroll', stickySocials)
}

function stickySocials() {
	dom.socials.classList.add('sticky');
	dom.limit = dom.footer.getBoundingClientRect().top;
	
	if (dom.socials.getBoundingClientRect().bottom + dom.offset >= dom.limit) {
		dom.socials.classList.remove('sticky');
	}
}

export default {
	init,
};