import { getLocation } from './utils/getLocation';

import HandleSidebarMobile from './HandleSidebarMobile';
import ProductReqwest from './ProductReqwest';
import Collapser from './Collapser';
import { isMobile } from './utils/isMobile';

let sidebarFiltersCS;
const currUrl = getLocation();
const csData = { csCategory: { faqCategory: '', manualCategory: '', searchWord: '' } };

function setEvents(isAdd) {
	const collapse = document.querySelectorAll('[data-expendable]');
	const fnc = isAdd ? 'addEventListener' : 'removeEventListener';
	Array.from(collapse).forEach((curr) => {
		curr[fnc]('click', Collapser.handleCollapser);
	});
}

function handleCatFilter(e) {
	let sidebarContext;
	ProductReqwest.loading('[data-product-content]');

	if (e) {
		const currCategory = e.currentTarget;
		sidebarContext = currCategory.getAttribute('data-sidebar-filter');
		Array.from(sidebarFiltersCS).forEach((curr) => {
			curr.classList.remove('selected');
		});
		currCategory.classList.add('selected');
	
		const categoryID = currCategory.getAttribute('data-cs-filter');
		csData.csCategory[`${sidebarContext}Category`] = categoryID;
	
		if (sidebarContext === 'faq') {
			setEvents(false);
		}

		searchKeyword();
	}

	const onLoaded = ProductReqwest.loadContent(currUrl, csData);

	return onLoaded.then(() => {
		if (sidebarContext && sidebarContext === 'faq') {
			setEvents(true);
		}
	});
}

function searchKeyword(e) {
	if (e) e.preventDefault();
	const search = document.querySelector('[data-keyword-faq]');
	if (!search) return;
	const { value } = search;

	csData.csCategory.searchWord = value;
	if (e) handleCatFilter();
}

function onResize() {
	HandleSidebarMobile.handleEvent();
}

export default {
	init() {
		sidebarFiltersCS = document.querySelectorAll('[data-cs-filter]');
		if (!sidebarFiltersCS) return;
		
		Array.from(sidebarFiltersCS).forEach((curr) => {
			curr.addEventListener('click', handleCatFilter);
		});

		setEvents(true);

		window.addEventListener('resize', onResize);
		onResize();

		const searchBox = document.querySelector('[data-search-box]');
		if (searchBox) {
			searchBox.reset();
			searchBox.addEventListener('submit', searchKeyword);
		}
	},
};
