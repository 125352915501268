
import { TweenMax } from 'gsap/TweenMax';

let btns;
let origPosition;
const states = ['bottom', 'top', 'sticky'];

function toggleStates(elem, active) {
	states.forEach(state => elem.classList[state === active ? 'add' : 'remove'](state));
}

function stickyBtn(btnsContainer) {
	const container = document.querySelector('[data-sidebar-inner]');
	const getPadding = window.getComputedStyle(btns).padding;
	const addedPadding = Number(getPadding.replace(/px/g, ''));

	const offset = (origPosition.height + (addedPadding * 2)) * 2;
	const sidebarContainer = document.querySelector('[data-sidebar-target]').getBoundingClientRect().width;
	TweenMax.set(btnsContainer, { height: origPosition.height });

	if (((container.getBoundingClientRect().top - window.innerHeight) + offset) <= 0 && (container.getBoundingClientRect().bottom - window.innerHeight) >= 0) {
		toggleStates(btns, 'sticky');

		TweenMax.set(btns, {
			top: '',
			width: sidebarContainer,
		});
	} else if (container.getBoundingClientRect().bottom <= window.innerHeight) {
		// console.log('bottom');
		toggleStates(btns, 'bottom');

		TweenMax.set(btns, {
			top: '',
			width: '',
		});
	} else {
		// console.log('top');
		toggleStates(btns, 'top');

		TweenMax.set(btns, {
			top: origPosition.height + (addedPadding * 2),
			width: '100%',
		});
	}
}

export default {
	setBtn() {
		btns = document.querySelector('[data-filter-btn-container]');

		origPosition = {
			top: btns.getBoundingClientRect().top,
			bottom: btns.getBoundingClientRect().bottom,
			left: btns.getBoundingClientRect().left,
			width: btns.getBoundingClientRect().width,
			height: btns.getBoundingClientRect().height,
		};	
	},

	trackBtn() {
		const btnsContainer = document.querySelector('[data-sticky-button-container]');
		if (window.matchMedia('(min-width: 991px)').matches) {
			stickyBtn(btnsContainer);
		}
	},
};
