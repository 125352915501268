'format es6';
'use strict';

import './utils/polyfills';

import $ from 'jquery';
import moment from 'moment';
import ns from './NameSpace';

import { docReady } from './utils/docReady';
import { isEdge } from './utils/browserDetect';

import Menu from './Menu';
import Forms from './Forms';
import TwoThirdSlider from './TwoThirdSlider';
import ProductSingleSlider from './ProductSingleSlider';
import FormValidation from './FormValidation';
import SidebarReqwest from './SidebarReqwest';
import FaqManualFilter from './FaqManualFilter';
import Popin, { popup } from './Popin';
import VideoPlayer from './VideoPlayer';
import VideoVisible from './VideoVisible';
import HotspotModule from './HotspotModule';
import Socials from './Socials';
import Like from './Like';
import Newsletter from './Newsletter';
import Cart from './Cart';
import Shop from './Shop';
import StickySidebar from './StickySidebar';
import Collapser from './Collapser';
import CatalogueFilters from './CatalogueFilters';
import Anchor from './Anchor';
import BuyButton from './BuyButton';

docReady.then(() => {
	if (isEdge([12, 13, 14])) {
		$('img').removeAttr('srcset').removeAttr('sizes');
	}

	[
		Forms,
		Menu,
		TwoThirdSlider,
		ProductSingleSlider,
		FormValidation,
		SidebarReqwest,
		FaqManualFilter,
		VideoPlayer,
		VideoVisible,
		HotspotModule,
		Socials,
		Like,
		Newsletter,
		Shop,
		StickySidebar,
		CatalogueFilters,
		Anchor,
		BuyButton,
		Popin,
	].forEach(c => c.init());

	const collapse = document.querySelectorAll('[data-expendable]');
	Array.from(collapse).forEach((curr) => {
		curr.addEventListener('click', Collapser.handleCollapser);
	});

	const retrievedObjectFromLS = localStorage.getItem('hasSeenPopin');
	const getLastSeenDate = retrievedObjectFromLS || '00-00-0000';
	const compareDates = retrievedObjectFromLS ? moment().format('DD-MM-YYYY') !== getLastSeenDate : true;
	
	if (!ns.detectedRegion) return;
	if (ns.detectedRegion !== ns.currentRegion && (!retrievedObjectFromLS || compareDates)) {
		popup(null, 'popin-region', true);
	}
});
