import ShopifyBuy from '@shopify/buy-button-js';
import { addPopin, updateCartIndicator } from './Cart';
import { cartOpt, lineItemOpt, toggleOpt, qty } from './BuyButtonOpt';
import ns from './NameSpace';

let ui;

// export function addCustomAttribute() {

// }

function updateLineItems() {
	ui.components.cart[0].model.lineItems.forEach((lineItem) => {
		const linetItemid = lineItem.id;
		const linetItemQty = lineItem.quantity;

		if (lineItem.customAttributes.length <= 0) {
			const lineItemsToAdd = [
				{
					id: linetItemid,
					quantity: linetItemQty,
					variantId: lineItem.variant.id,
					customAttributes: [{key: "baba", value: "blues"}],
				},
			];
	
			const checkoutId = localStorage.getItem('3faa2fca35e78b0ff6e253ed350b998b.artika-can.myshopify.com.checkoutId');
			// Add an item to the checkout
			ui.client.checkout.updateLineItems(checkoutId, lineItemsToAdd).then((checkout) => {
				// Do something with the updated checkout
				console.log(checkout.lineItems); // Array with one additional line item
			});
		}
	});
}

export function openCart(e) {
	e.stopPropagation();

	updateLineItems();
	
	ui.openCart();
	document.querySelector('body').classList.add('menu-opened');
}

export function menuCartListener(cartOnly = false) {
	document.querySelector('[data-menu-cart]').addEventListener('click', openCart);
}

function getCurrencySettings() {
	const language = ns.lang;
	const moneyConfig = language === 'fr' ? '%7B%7Bamount%7D%7D %24' : '%24%7B%7Bamount%7D%7D';
	const moneyRegion = ns.currentRegion === 'ca' ? ' CAD' : ' USD';

	return `${moneyConfig + moneyRegion}`;
}

function createComponent(button) {
	const productId = button.getAttribute('data-button-component');
	const currencySettings = getCurrencySettings();

	ui.createComponent('product', {
		id: [productId],
		node: button,
		moneyFormat: currencySettings,
		options: {
			product: {
				iframe: false,
				variantId: 'all',
				width: 'auto',
				contents: {
					img: false,
					imgWithCarousel: false,
					title: false,
					variantTitle: false,
					price: false,
					description: false,
					buttonWithQuantity: false,
					quantity: true,
					quantityIncrement: true,
					quantityDecrement: true,
					quantityInput: true,
				},
				classes: {
					button: 'button-flat-bordered shopify-buy__btn',
				},
				templates: {
					quantity: qty,
				},
				text: {
					button: ns.buyButton.productComponent.addToCart,
					outOfStock: ns.buyButton.productComponent.outOfStock,
					unavailable: ns.buyButton.productComponent.unavailable,
				},
				events: {
					addVariantToCart: (product) => { 
						addPopin(product, ui);

						// we update the customAttribute of the line item only if he exist. Otherwise, it'll be updated when the user will visualize his cart.
						const getLineItemId = ui.components.cart[0].model.lineItems.reduce((acc, lineItem) => {
							return acc === lineItem.variant.id ? lineItem.id : product.defaultStorefrontVariantId;
						}, product.defaultStorefrontVariantId);

						if (product.defaultStorefrontVariantId !== getLineItemId) {
							updateLineItems();
						}
					},
				},
				DOMEvents: {
					'click .shopify-buy__btn': (evt) => {
						evt.preventDefault();
						ui.closeCart();
						document.querySelector('body').classList.remove('menu-opened');
					},
				},
			},
			cart: cartOpt,
			lineItem: lineItemOpt,
			toggle: toggleOpt,
		},
	});

	menuCartListener();
}

function createCart() {
	const currencySettings = getCurrencySettings();
	ui.createComponent('cart', {
		moneyFormat: currencySettings,
		options: {
			cart: cartOpt,
			lineItem: lineItemOpt,
			toggle: toggleOpt,
		},
	});
}

function ShopifyBuyInit() {
	const client = ShopifyBuy.buildClient({
		domain: ns.shopifydomain,
		storefrontAccessToken: ns.storefrontAccessToken,
	});
	ui = ShopifyBuy.UI.init(client);

	const hasAddToCart = document.querySelector('[data-button-component]');
	if (!hasAddToCart) {
		// if there's no buy button on the page, we create cart
		createCart(ui);
	} else {
		// if there's buy button on the page, we create the components and it's will create the cart as well
		createComponent(hasAddToCart, ui);
	}
}

export default {
	init() {
		ShopifyBuyInit();
	},
};
