'format es6';
'use strict';

import { isElementVisible } from './utils/isElementVisible';

let videos;

function onScroll() {
	videos.forEach((vid) => {
		const isVisible = isElementVisible(vid);

		if (isVisible) {
			vid.play();
		} else if (!isVisible) {
			vid.pause();
		}
	});
}

export default {
	init() {
		const body = document.querySelector('body');
		videos = Array.from(body.querySelectorAll('[data-video-loop]'));

		window.addEventListener('scroll', (e) => {
			onScroll(e, true);
		});
		onScroll(null, true);
	},
};
