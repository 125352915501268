'format es6';
'use strict';

import QueryString from '../../node_modules/query-string';
import RangeSlider from './RangeSlider';
import StickySearchBtn from './StickySearchBtn';

const filterContainer = 'data-catalogue-filters';

function reloadWindow(e, filter) {
	// l'objet search keyword écrase tout les filtres, mais pas la category, pour l'instant pas un problème tant qu'on ne sait pas ce qui se passe avec la page all products
	const currProduct = e.currentTarget;
	window.location.search = `search[${filter}]=${currProduct.value}`;
}

function updateSearchObject(e, filter) {
	const currProduct = e.currentTarget ? e.currentTarget : e;
	const searchObject = QueryString.parse(window.location.search);
	
	searchObject[`search[${filter}]`] = currProduct.value;

	const newSearchObject = QueryString.stringify(searchObject);

	window.location.search = newSearchObject;
}

function onResize() {
	StickySearchBtn.trackBtn(filterContainer);
}

function resetAll(e) {
	e.preventDefault();
	// document.querySelector('[data-filter-form]').reset();
	// RangeSlider.destroy();

	const searchObject = QueryString.parse(window.location.search);

	const currCat = searchObject['search[category]'];
	window.location.search = `search[category]=${currCat}`;
}

export default {
	init() {
		const sidebar = document.querySelector('[data-catalogue-filters]');
		if (!sidebar) return;

		RangeSlider.init();

		const productCategory = document.querySelector('[data-product-category-select]');
		productCategory.addEventListener('change', (e) => reloadWindow(e, 'category'));

		const searchBox = document.querySelector('[data-search-box]');
		const searchInput = searchBox.querySelector('[data-keyword]');
		searchBox.addEventListener('submit', () => updateSearchObject(searchInput, 'keyword'));

		const filterBox = document.querySelector('[data-filter-by]');
		filterBox.addEventListener('change', (e) => updateSearchObject(e, 'filterBy'));

		const resetBtn = document.querySelector('[data-reset]');
		if (resetBtn) {
			resetBtn.addEventListener('click', resetAll);
		}

		StickySearchBtn.setBtn();
		window.addEventListener('resize', onResize);
		onResize();

		window.addEventListener('scroll', StickySearchBtn.trackBtn);
		StickySearchBtn.trackBtn(filterContainer);
	},
};
