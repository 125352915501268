'format es6';
'use strict';

import $ from 'jquery';
import Promise from 'bluebird';
try{require('source-map-'+'support').install();}catch(e){}

const name = 'artika';

const ns = window[name] = (window[name] || {});

ns.window = $(window);

export default ns;

