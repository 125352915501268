'format es6';

import { TweenMax, Expo } from 'gsap/TweenMax';
import imagesLoaded from 'imagesloaded';
import ns from './NameSpace';
import { openCart } from './BuyButton';

const timeoutList = {};
let popinArea;

function elemFromHTML(html) {
	const elem = document.createElement('div');
	elem.innerHTML = html;
	return elem.children[0];
}

export function updateCartIndicator(qty, component) {
	const cartCount = document.querySelector('[data-cart-count');
	if (component) {
		const item = component.cart.model;
		const getTotalItem = item.lineItems.reduce((acc, lineItem) => {
			return acc + lineItem.quantity;
		}, 0);
		cartCount.innerHTML = getTotalItem;
	} else {
		cartCount.innerHTML = qty;
	}
	
	if (!cartCount.classList.contains('active') && cartCount.textContent > 0) {
		cartCount.classList.add('active');
	} else if (cartCount.textContent <= 0) {
		cartCount.classList.remove('active');
	}
}

export function addPopin(product, ui) {
	const productId = product.id;
	const productBox = document.querySelector(`[data-product-box="${productId[0]}"]`);
	const POPINDATA = 'data-product-popin-id';
	popinArea = document.querySelector('[data-popin-area]');

	const addedElement = {
		confirmation: ns.productAddedToCartPopin,
		name: productBox.querySelector('[data-product-name]').textContent,
		price: productBox.querySelector('[data-product-price]').textContent,
		qty: product.selectedQuantity,
		image: product.cachedImage.src,
	};

	const popin = `
		<div class="popin-product product-box product-box-horizontal" data-product-popin-id="${productId}">
			<div class="close" data-close></div>
			<div class="added-product">${addedElement.confirmation}</div>
			<a href="#" class="link-overlay" data-popin-cart></a>
			
			<div class="product-image">
				<img src="${addedElement.image}" alt="${addedElement.name}"/>
			</div>

			<div class="product-detail">
				<div class="product-name">${addedElement.name}</div>
				<div class="product-qty">${ns.buyButton.productComponent.quantity} : <span data-qty-value>${addedElement.qty}</span></div>
				<div class="product-bottom">
					<div class="product-price">${addedElement.price}</div>
					<button class="button-underline">${ns.seeCart}</button>
				</div>
			</div>
		</div>
	`;
	
	const hasSamePopin = popinArea.querySelector(`[${POPINDATA}="${productId}"]`);
	const getPopins = popinArea.querySelectorAll('[data-product-popin-id]');

	// fonction pour remove le premier en haut s'il y en as plus que 3 de adder en même temps
	if (getPopins.length > 1 && !hasSamePopin) {
		// regarder lequel qui est pas entrain de s'animé out au lieu de celui au top.. parce qu'il pourrait etre entrain de s'animé et être le premier quand meme
		const f = getPopins[0].getAttribute('data-timeout');
		hidePopin(f);
	}

	if (hasSamePopin) {
		const activePopin = hasSamePopin.querySelector('[data-qty-value]');
		const getPopinValue = Number(activePopin.textContent);
		hasSamePopin.querySelector('[data-qty-value]').innerHTML = getPopinValue + addedElement.qty;
		removeTimeout(productId);
		createNewTimeout(productId);
	} else {
		const justAddedPopin = elemFromHTML(popin);
		popinArea.appendChild(justAddedPopin);

		justAddedPopin.querySelector('[data-close]').addEventListener('click', close);
		justAddedPopin.querySelector('[data-popin-cart]').addEventListener('click', (e) => handleClickPopin(e, ui, justAddedPopin));

		const loadedImages = new Promise((resolve) => {
			imagesLoaded(justAddedPopin, resolve);
		});

		loadedImages.then(() => {
			TweenMax.to(justAddedPopin, 0.5, {
				opacity: 1,
				y: 0,
				ease: Expo.easeOut,
				onComplete: () => {
					justAddedPopin.setAttribute('data-timeout', productId);
					createNewTimeout(productId);
				},
			});
		});
	}
}

function handleClickPopin(e, ui, justAddedPopin) {
	e.preventDefault();
	e.stopPropagation();

	openCart(e);
	close(null, justAddedPopin);
}

function createNewTimeout(key) {
	const timeout = setTimeout(() => {
		hidePopin(key);
	}, 5000);

	timeoutList[key] = timeout;
}

function removeTimeout(key) {
	window.clearTimeout(timeoutList[key]);
	delete timeoutList[key];
}

function hidePopin(key) {
	removeTimeout(key);
	const t = popinArea.querySelector(`[data-timeout="${key}"]`);
	removePopin(t);
}

function close(e, popin) {
	const key = e ? e.currentTarget.parentNode.getAttribute('data-timeout') : popin.getAttribute('data-timeout');
	hidePopin(key);
}

function removePopin(e) {
	const parent = e.currentTarget ? e.currentTarget.parentNode : e;

	if (e.currentTarget) {
		e.currentTarget.removeEventListener('click', removePopin);
	} else {
		parent.querySelector('[data-close]').removeEventListener('click', removePopin);
	}

	TweenMax.to(parent, 0.5, {
		opacity: 0,
		y: -10,
		ease: Expo.easeOut,
		onComplete: () => {
			TweenMax.to(parent, 0.5, {
				height: 0,
				paddingTop: 0,
				paddingBottom: 0,
				marginTop: 0,
				ease: Expo.easeOut,
				onComplete: () => {
					popinArea.removeChild(parent);
				},
			});
		},
	});
}
