
const dom = {};
const OFFSET = 150;
const states = ['absolute', 'top', 'sticky'];
let isWaitingForFrame = false;

function resetPos() {
	selectSidebar();
	if (!dom.sidebarInner) return;

	toggleStates(dom.stickySidebar, '');
}

function init() {
	selectSidebar();
	if (!dom.sidebarInner) return;
	window.addEventListener('scroll', onScroll);
	window.addEventListener('resize', onResize);

	if (window.matchMedia('(max-width: 991px)').matches) {
		dom.isMobile = false;
	} else {
		dom.isMobile = true;
	}
}

function selectSidebar() {
	dom.stickySidebar = document.querySelector('[data-sticky-sidebar]');
	if (!dom.stickySidebar) return;
	dom.sidebarInner = dom.stickySidebar.querySelector('[data-sticky-inner]');
}

function toggleStates(elem, active) {
	states.forEach(state => elem.classList[state === active ? 'add' : 'remove'](state));
}

function doScrollHandler() {
	isWaitingForFrame = false;
	const pos = dom.stickySidebar.getBoundingClientRect();
	const inner = dom.sidebarInner.getBoundingClientRect();
	dom.content = document.querySelector('[data-product-container]').getBoundingClientRect();

	if (pos.top <= OFFSET - pos.height + inner.height && dom.content.height > inner.height) {
		toggleStates(dom.stickySidebar, 'absolute');
	} else if (pos.top <= OFFSET && dom.content.height > inner.height) {
		toggleStates(dom.stickySidebar, 'sticky');
	} else {
		toggleStates(dom.stickySidebar, 'top');
	}
}

function onScroll() {
	if (!isWaitingForFrame) {
		isWaitingForFrame = true;
		requestAnimationFrame(doScrollHandler);
	}
}

function onResize() {
	if (window.matchMedia('(max-width: 991px)').matches && !dom.isMobile) {
		dom.isMobile = true;
		toggleStates(dom.stickySidebar, 'top');
		window.removeEventListener('scroll', onScroll);
	} else if (window.matchMedia('(min-width: 992px)').matches && dom.isMobile) {
		dom.isMobile = false;
		window.addEventListener('scroll', onScroll);
	}
}

export default {
	init,
	resetPos,
};
