'format es6';
import { slideToggle } from './utils/slide';

export default {
	handleCollapser(e) {
		e.preventDefault();
		const clicked = e.currentTarget;
		const rootId = clicked.getAttribute('data-expendable');
		const control = document.querySelector(`[data-expendable-control="${rootId}"]`);
		const target = document.querySelector(`[data-expendable-target="${rootId}"]`);
	
		control.classList.toggle('open');
		slideToggle(target);
	},
};
