'format es6';

import moment from 'moment';

function handleEvent(currPopin, setLS) {
	const body = document.querySelector('body');
	body.classList.toggle('opened');
	currPopin.classList.toggle('opened');

	if (setLS) {
		const today = moment().format('DD-MM-YYYY');
		localStorage.setItem('hasSeenPopin', today);
	}
}

export function popup(e, clicked, setLS) {
	const clickedPopin = e ? e.currentTarget.getAttribute('data-open-popin') : clicked;
	const popin = document.querySelector(`[data-${clickedPopin}]`);
	handleEvent(popin);

	const closePopin = popin.querySelectorAll(`[data-close-${clickedPopin}]`);
	Array.from(closePopin).forEach((curr) => {
		curr.addEventListener('click', () => handleEvent(popin, setLS));
	});
}

export default {
	init() {
		const btnRetailers = document.querySelectorAll('[data-open-popin]');
		if (btnRetailers.length <= 0) return;

		Array.from(btnRetailers).forEach((curr) => {
			curr.addEventListener('click', popup);
		});
	},
};
